import React, { useState, useEffect, useContext } from "react"
import EmpresaContext from "../../../../contexts/Empresa"
import api from "../../../../services/api"
import Select from "react-select"
// @ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert"
import Select2 from "react-select2-wrapper"

import { Button, Modal, FormGroup, Spinner } from "reactstrap"

interface ModalEncerrarProps {
	oportunidades: []
	notify: any
	showModalEncerrar: boolean
	onCloseEncerramento(): void
	onSuccess(): void
}

const ModalEncerrar: React.FC<ModalEncerrarProps> = ({
	onCloseEncerramento,
	showModalEncerrar,
	oportunidades,
	notify,
	onSuccess,
}) => {
	const { empresaSelecionada } = useContext(EmpresaContext)
	const [showModal, setShowModal] = useState(false)
	const [encerramentos, setEncerramentos] = useState([])
	const [encerramento, setEncerramento] = useState(null)
	const [loading, setLoading] = useState(false)
	const [alert, setAlert] = useState<any>(null)
	const [saving, setSaving] = useState(false)

	useEffect(() => {
		if (encerramentos.length === 0) {
			loadEncerramentos()
		}
	}, [])

	useEffect(() => {
		setEncerramento(null)
	}, [showModal])

	async function loadEncerramentos() {
		try {
			const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/motivos-encerramentos`, {
				params: {
					aplicacoes: ["encerramento", "null"],
				},
			})
			setEncerramentos(response.data)
		} catch (error) {
			console.log(error)
			if (notify) notify("danger", "Não foi possível carregar motivos de encerramentos")
		}
	}

	async function handlerEncerrar() {
		if (!encerramento) return
		setSaving(true)
		setAlert(null)
		try {
			await api.post(`/common/empresas/${empresaSelecionada?.id}/oportunidades/encerrar-oportunidades`, {
				//@ts-ignore
				motivo_encerramento_id: encerramento.value,
				oportunidadeIds: oportunidades,
				empresa_id: empresaSelecionada?.id,
			})
			showSuccess("Oportunidades encerradas")
		} catch (error) {
			console.log(error)
			if (notify) notify("danger", "Não foi possível encerrar oportunidades")
		}
		setSaving(false)
	}

	function showSuccess(msg: any) {
		onSuccess()
		setAlert(
			<ReactBSAlert
				success
				title={msg}
				onConfirm={() => setAlert(null)}
				onCancel={() => setAlert(null)}
				showConfirm={false}
				timeout={2000}
			/>
		)
		notify("success", `${oportunidades.length} oportunidades encerradas`)
		onCloseEncerramento()
	}

	function handleClose() {
		if (!saving) onCloseEncerramento()
	}

	return (
		<>
			<Modal
				className="modal-dialog-centered modal-danger"
				size="md"
				isOpen={showModalEncerrar}
				toggle={handleClose}
			>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-notification">
						Encerrar oportunidade
					</h6>
				</div>
				<div className="modal-body">
					<div className="py-3 text-center">
						<i className="fas fa-exclamation-circle ni-3x" />
						<h4 className="heading mt-4"></h4>
						<p>
							Você está prestes a encerrar {oportunidades.length} oportunidades. Selecione um motivo de
							encerramento para prosseguir.
						</p>
						<FormGroup>
							<Select
								onChange={(e: any) => setEncerramento(e)}
								value={encerramento}
								className="text-dark"
								placeholder="Selecione um motivo encerramento..."
								options={encerramentos.map((item: any) => ({
									value: item.id,
									label: item.nome,
								}))}
							/>
						</FormGroup>
					</div>
				</div>
				<div className="modal-footer">
					<Button
						className="text-white ml-auto"
						color="link"
						data-dismiss="modal"
						type="button"
						onClick={onCloseEncerramento}
					>
						Fechar
					</Button>
					<Button
						onClick={() => handlerEncerrar()}
						className="btn-white"
						color="default"
						type="button"
						disabled={saving || encerramento == null}
					>
						{saving && <Spinner size="sm" className="mr-2" />}
						Encerrar
					</Button>
				</div>
			</Modal>
		</>
	)
}
export default ModalEncerrar
