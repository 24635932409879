import React, { useEffect, useState, useRef, useContext } from "react"
import api from "../../../../services/api"
import moment from "moment"
import AuthContext from "../../../../contexts/Auth"
import EmpresaContext from "../../../../contexts/Empresa"
import { useHistory } from "react-router-dom"

import Produtos from "../Produtos"
import Comentarios from "../Comentarios"
import TagsInput from "react-tagsinput"
import Select from "react-select"
import Select2 from "react-select2-wrapper"
import ReactDatetime from "react-datetime"
import NotificationAlert from "react-notification-alert"
import ReactQuill from "react-quill"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Header from "../Header"
import Encerrar from "../Encerrar"
import FormCliente from "../../../common/Clientes/Form"
import EnviarEmailModal from "../../../common/Clientes/EmailAvulso"
import Telefones from "../Telefones"
import FuncionarioSelect from "../FuncionarioSelect"
import Preview from "../../Publicacoes/Preview"
import { ClienteInputContainer, AnuncioInputContainer, FABConversas } from "./styles"
import {
	Spinner,
	Badge,
	Modal,
	Button,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Container,
	Row,
	Col,
	FormGroup,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Input,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Nav,
	NavItem,
	NavLink,
	TabPane,
	TabContent,
	Tooltip,
	Table as RSTable,
	Table,
} from "reactstrap"
import EmailsOrSMSList from "../EmailsOrSMSList"
import Vendas from "../../../proto/Vendas"
import { useWhatsappContext } from "../../../../contexts/Whatsapp"
import ChatWindow from "../../../../components/Conversas/ChatWindow"
import { createColumns } from "../Columns"

let lastClientesSearch = []
export default (
	{ oportunidadeId, readOnly, oportunidadeIdToCrossSelling, tipoCrossSelling, coordenadas },
	...props
) => {
	const history = useHistory()
	const { empresaSelecionada } = useContext(EmpresaContext)
	const { auth, hasPermission, user } = useContext(AuthContext)
	const { selectContact } = useWhatsappContext()
	const [id, setId] = useState(null)
	const [oportunidade, setOportunidade] = useState({})
	const [cliente, setCliente] = useState(null)
	const [expectativa, setExpectativa] = useState(null)
	const [campanha, setCampanha] = useState(null)
	const [anuncio, setAnuncio] = useState(null)
	const [dataAgendamento, setDataAgendamento] = useState(null)
	const [comentarios, setComentarios] = useState(null)
	const [tags, setTags] = useState([])
	const [estagio, setEstagio] = useState({})
	const [comentarioId, setComentarioId] = useState(null)
	const [userId, setUserId] = useState(undefined)
	const [emailsAndSMS, setEmailsAndSMS] = useState(null)
	const [contatoPlayneeConversas, setContatoPlayneeConversas] = useState(null)

	const [telefones, setTelefones] = useState([])
	const [expectativas, setExpectativas] = useState([])
	const [campanhas, setCampanhas] = useState([])
	const [anuncios, setAnuncios] = useState([])
	const [produtos, setProdutos] = useState([])

	const [erros, setErros] = useState({})
	const [alert, setAlert] = useState(null)
	const [showTelefones, setShowTelefones] = useState(false)
	const [saving, setSaving] = useState(false)

	const notificationAlert = useRef()
	const clieneSelect = useRef()

	const [showClienteModal, setShowClienteModal] = useState(false)
	const [showEmailAvulso, setShowEmailAvulso] = useState(false)
	const [emailAvulso, setEmailAvulso] = useState(null)

	const [publicacaoPreviewId, setPublicacaoPreviewId] = useState(null)
	const [showPreview, setShowPreview] = useState(false)

	const [currentdataAgendamento, setCurrentdataAgendamento] = useState("")

	const [tooltipOpen, setTooltipOpen] = useState(false)
	const toggleToolTip = () => setTooltipOpen(!tooltipOpen)

	const [newEmailAvulso, setNewEmailAvulso] = useState(false)

	// State for current active Tab
	const [currentActiveTab, setCurrentActiveTab] = useState("1")

	const [modal, setModal] = useState(false)

	const toggleVendas = () => setModal(!modal)

	const [oportunidadesExistentes, setOportunidadesExistentes] = useState([])

	const [mensagens, setMensagens] = useState([])

	const [obeservationTable, setObservationTable] = useState(null)

	// Toggle active state for Tab
	const toggle = (tab) => {
		if (currentActiveTab !== tab) setCurrentActiveTab(tab)
	}

	const handleCloseModal = () => {
		setNewEmailAvulso(false)
	}

	async function checarOportunidadeExistente() {
		try {
			const response = await api.get(`marketing/empresas/${empresaSelecionada?.id}/oportunidades/filter`, {
				params: {
					cliente_id: cliente,
					user_id: user.id,
				},
			})

			if (response.data.length > 0) {
				setOportunidadesExistentes(response.data)
				setIsModalVisible(true)
			}
		} catch (error) {
			console.error(error)
			notify("danger", "Não foi possível checar a existência de oportunidades em aberto.")
		}
	}

	useEffect(() => {
		onClienteChange()
		if (isNew() && cliente) {
			checarOportunidadeExistente()
		}
	}, [cliente])

	useEffect(() => {
		dataAgendamento && setCurrentdataAgendamento(moment(dataAgendamento))
	}, [dataAgendamento])

	useEffect(() => {
		lastClientesSearch = []
		if (expectativas.length === 0) {
			loadExpectativas()
		}
		if (campanhas.length == 0) {
			loadCampanhas()
		}
	}, [])

	useEffect(() => {
		if (campanha && campanha.id > 0) {
			loadAnuncios(campanha.id)
		}
		setAnuncios([])
		setAnuncio(null)
	}, [campanha])

	useEffect(() => {
		if (oportunidadeId && oportunidadeId != id) {
			setId(oportunidadeId)
			loadOportunidade(oportunidadeId)
			loadContatoOportunidade(oportunidadeId)
		}
	}, [oportunidadeId])

	useEffect(() => {
		if (oportunidadeIdToCrossSelling && oportunidadeIdToCrossSelling > 0) {
			loadOportunidadeToCrossSelling(oportunidadeIdToCrossSelling)
		}
	}, [oportunidadeIdToCrossSelling])

	useEffect(() => {
		if (!userId) setTimeout(() => setUserId(null), 1000)
	}, [userId])

	//Carrega os dados na tela
	useEffect(() => {
		if (isOportunidadeLoaded()) {
			fillFields()
			loadEmailsAndSMS(oportunidade.cliente_id)
			loadMensagensConversas(oportunidade.id)
		}
	}, [oportunidade])

	async function loadMensagensConversas(oportunidadeId) {
		try {
			const { data: mensagens } = await api.get(
				`/meta/messages/oportunidades/${oportunidadeId}?user_id=${oportunidade.user_id}`
			)
			setMensagens(mensagens)
		} catch (error) {
			console.error("Ocorreu um erro ao tentar carregar as mensagens.", error)
		}
	}

	//carrega os dados de anuncio na tela

	function isOportunidadeLoaded() {
		return oportunidade && oportunidade.id
	}

	function onClienteChange() {
		const clienteSelecionado = lastClientesSearch.find((item) => item.id == cliente)
		if (clienteSelecionado) {
			setTags((clienteSelecionado.tags && clienteSelecionado.tags.split(",")) || [])
		}
	}

	const notify = (type, msg) => {
		let options = {
			place: "tc",
			message: (
				<div className="alert-text">
					<span data-notify="message">{msg}</span>
				</div>
			),
			type: type,
			icon: "ni ni-bell-55",
			autoDismiss: 3,
		}
		if (notificationAlert) notificationAlert.current.notificationAlert(options)
	}

	async function fillFields() {
		setExpectativa(oportunidade.expectativa_id)
		var camp = {
			value: oportunidade.campanha_id,
			label: oportunidade.anuncio.campanha.nome,
			id: oportunidade.campanha_id,
		}
		setCampanha(camp)
		setComentarios(oportunidade.comentarios ? oportunidade.comentarios.descricao : null)
		setComentarioId(oportunidade.comentarios ? oportunidade.comentarios.id : null)
		setDataAgendamento(oportunidade.data_agendamento ? moment(oportunidade.data_agendamento) : null)
		oportunidade?.data_agendamento && setCurrentdataAgendamento(oportunidade.data_agendamento)
		await fillCliente(oportunidade.cliente)
	}

	async function fillCliente(cliente) {
		setTags((cliente.tags && cliente.tags.split(",")) || [])
		setCliente(cliente.id)

		const select = document.getElementById("select-cliente")
		const option = document.createElement("option")
		option.text = cliente.nome
		option.value = cliente.id
		select.innerHTML = ""
		select.appendChild(option)

		await loadTelefonesCliente(cliente.id)
	}

	async function loadTelefonesCliente(clienteId) {
		try {
			const response = await api.get(
				`common/empresas/${empresaSelecionada?.id}/clientes/${clienteId}/telefonesbycliente`
			)
			const data = await response.data
			setTelefones(data)
		} catch (error) {
			console.error(error)
			notify("danger", "Não é possível carregar telefones")
		}
	}

	async function loadOportunidade(id) {
		try {
			if (!id) return
			const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${id}`)
			const op = await response.data
			setOportunidade(op)
		} catch (error) {
			console.log(error)
			notify("danger", "Não foi possível carregar oportunidade")
		}
	}

	async function loadContatoOportunidade(id) {
		try {
			if (!id) return

			const { data: contato } = await api.get(`/meta/contato?oportunidade_id=${id}`)
			setContatoPlayneeConversas(contato)
		} catch (error) {
			console.log(error)
			notify("danger", "Não foi possível carregar contato")
		}
	}

	function selecionarContatoPlayneeConversas() {
		if (contatoPlayneeConversas) {
			selectContact(contatoPlayneeConversas)
			history.push(`/admin/conversas`)
		}
	}

	async function loadOportunidadeToCrossSelling(id) {
		try {
			const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${id}`)
			const op = await response.data
			if (op) {
				setExpectativa(op.expectativa_id)
				fillCliente(op.cliente)
				setUserId(op.user_id)
			}
		} catch (error) {
			console.log(error)
			notify("danger", "Não foi possível carregar oportunidade para cross-selling")
		}
	}

	async function loadExpectativas() {
		const response = await api.get(`marketing/expectativas`)
		if (response.data) {
			setExpectativas(response.data)
		}
	}

	async function loadCampanhas() {
		const response = await api.get(`marketing/campanha/${empresaSelecionada?.id}`, {
			params: {
				situacao: "ativo",
			},
		})
		setCampanhas(response.data)
	}

	async function loadAnuncios(campanha_id) {
		if (campanha_id) {
			const response = await api.get(
				`common/empresas/${empresaSelecionada?.id}/campanhas/${campanha_id}/anuncios`,
				{
					params: {
						situacao: "ativo",
					},
				}
			)
			setAnuncios(response.data)
			if (response.data.length > 0 && campanha.id == oportunidade.campanha_id) {
				var anun = {
					value: oportunidade.anuncio.id,
					label: oportunidade.anuncio.nome,
					id: oportunidade.anuncio.id,
				}
				setAnuncio(anun)
			}
		}
	}

	const handleTags = (tagsinput) => {
		if (!tagsinput) return
		const newTags = tagsinput.map((tag) => tag.trim().toLowerCase())
		let unique = [...new Set(newTags)]
		setTags(unique)
	}

	async function restaurarOportunidade() {
		setAlert(null)
		if (!oportunidade.id) return
		try {
			setSaving(true)
			const response = await api.patch(
				`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidade.id}/restaurar-oportunidades`
			)
			const data = await response.data
			loadOportunidade(data.id)
		} catch (error) {
			console.error(error)
			notify("danger", "Não foi possível restaurar oportunidade")
		}
		setSaving(false)
	}

	async function handleRestaurarOportunidade() {
		setAlert(
			<ReactBSAlert
				warning
				title="Restaurar oportunidade?"
				onConfirm={restaurarOportunidade}
				onCancel={() => setAlert(null)}
				showCancel
				confirmBtnBsStyle="primary"
				confirmBtnText="Sim"
				cancelBtnBsStyle="link"
				cancelBtnText="Não"
				btnSize=""
			/>
		)
	}

	function goToNewCrossSelling() {
		history.push("/admin/oportunidades/new", {
			oportunidadeToCrossSelling: oportunidade.id,
			tipoCrossSelling: "oportunidade_encerrada",
		})
	}

	async function criarNovaOportunidadeCrossSelling() {
		try {
			//volta pra tela de oportunidades, e logo após abre a tela Nova oportunidade carregando os dados
			//de cliente, produtos declinados e espectativa da oportunidade encerrada como venda.
			setTimeout(history.goBack, 200)
			setTimeout(goToNewCrossSelling, 500)
		} catch (error) {
			console.log(error)
			if (notify) notify("danger", "Não foi possível abrir nova oportunidade")
		}
	}

	async function handleCrossSellingOportunidade() {
		setAlert(
			<ReactBSAlert
				warning
				title="Criar Cross-Selling?"
				onConfirm={criarNovaOportunidadeCrossSelling}
				onCancel={() => setAlert(null)}
				showCancel
				confirmBtnBsStyle="primary"
				confirmBtnText="Sim"
				cancelBtnBsStyle="link"
				cancelBtnText="Não"
				btnSize=""
			>
				Deseja criar uma nova oportunidade a partir dos dados desta oportunidade encerrada?
			</ReactBSAlert>
		)
	}

	function goToEdit() {
		history.push(`/admin/oportunidades/${new Number(oportunidade.id)}/edit`)
	}

	async function handleSalvar(manterTela) {
		try {
			setSaving(true)
			setErros({})

			const formData = oportunidade
			formData.expectativa_id = expectativa
			formData.produtos = produtos
			formData.anuncio_id = anuncio.id
			formData.campanha_id = campanha.id
			formData.comentarios = comentarios
			formData.estagio_id = estagio.id ? estagio.id : 1
			formData.expectativa_id = expectativa
			formData.cliente_id = cliente
			formData.data_agendamento = dataAgendamento
			formData.user_id = userId
			formData.tags = tags.join()
			if (isNew()) {
				formData.latitude = coordenadas && coordenadas.latitude ? coordenadas.latitude : null
				formData.longitude = coordenadas && coordenadas.longitude ? coordenadas.longitude : null
			}

			const url = isNew()
				? `/common/empresas/${empresaSelecionada?.id}/oportunidades`
				: `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidade.id}`

			if (isNew()) {
				const response = await api.post(url, formData, {
					headers: { main_user_id: JSON.parse(localStorage.getItem("@playnee-empresa")).pivot.user_id },
				})
				oportunidade.id = response.data.id
				afterInsert("Oportunidade adicionada", manterTela)
			} else {
				formData.comentarios_id = comentarioId
				await api.put(url, formData)
				afterUpdate("Oportunidade alterada", manterTela)
			}

			loadOportunidade(oportunidadeId)
		} catch (error) {
			console.log(error)
			if (error.response) {
				const response = error.response
				notify("danger", "Alguns campos estão inválidos")
				if (response.data.length) {
					const responseError = {}
					for (let e of response.data) {
						responseError[e.field] = e.message
					}
					setErros(responseError)
				}
			} else {
				notify("danger", "Não foi possível salvar oportunidade.")
			}
		}
		setSaving(false)
	}

	const afterInsert = (msg, manterTela) => {
		setAlert(
			<ReactBSAlert
				success
				style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
				title={msg}
				onConfirm={() => history.goBack()}
				confirmBtnBsStyle="success"
				showConfirm={false}
				btnSize=""
			/>
		)

		if (manterTela) {
			setTimeout(history.goBack, 2000)
			setTimeout(goToEdit, 2100)
		} else {
			setTimeout(history.goBack, 2000)
		}
	}

	const onColaboradorChanged = () => {
		afterUpdate("Encaminhado")
	}

	const afterUpdate = (msg, manterTela) => {
		setAlert(
			<ReactBSAlert
				success
				style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
				title={msg}
				onConfirm={() => history.goBack()}
				confirmBtnBsStyle="success"
				showConfirm={false}
				btnSize=""
			/>
		)

		if (manterTela) {
			setTimeout(setAlert, 2000)
		} else {
			setTimeout(history.goBack, 2000)
		}
	}

	function isNew() {
		return !oportunidadeId
	}

	function clienteOptions() {
		const option = {
			placeholder: "Selecione um cliente...",
		}
		option["disabled"] = !isNew()
		oportunidade["processResults"] = ({ data }) => console.log(data)
		if (!oportunidadeId) {
			option["ajax"] = {
				url: `${process.env.REACT_APP_API_URL}/common/empresas/${empresaSelecionada?.id}/clientes`,
				dataType: "json",
				headers: {
					Authorization: `Bearer ${auth.token}`,
				},
				processResults: function (data) {
					lastClientesSearch = data.data
					return {
						results: data.data.map((item) => ({
							id: item.id,
							text: `${item.nome} ${item.email ? `| ${item.email}` : ""} ${
								item.ddd ? `| (${item.ddd})` : ""
							} ${item.numero ? `${item.numero.slice(0, 5)} - ${item.numero.slice(5)}` : ""} ${
								item.cpf_cnpj ? ` | ${item.cpf_cnpj}` : ""
							}`,
						})),
					}
				},
				data: function (params) {
					if (!params.term) return {}
					var query = {
						nome: params.term,
						cpfCnpj: params.term,
						email: params.term,
						telefone: {
							ddd: params.term.slice(0, 2),
							numero: params.term.slice(2),
						},
					}
					return query
				},
			}
		}
		return option
	}

	const ClienteInputGoup = () => {
		return (
			<>
				{!readOnly && (
					<InputGroupAddon addonType="append">
						<Button
							title={cliente ? "Alterar Cliente" : "Novo cliente"}
							color="primary"
							onClick={() => setShowClienteModal(true)}
							outline
						>
							<span className="btn-inner--icon">
								<i className={cliente ? "fas fa-edit" : "ni ni-fat-add"}></i>
							</span>
						</Button>
					</InputGroupAddon>
				)}

				{oportunidade.id && (
					<InputGroupAddon addonType="append">
						<Button
							size="md"
							title="telefones"
							color="primary"
							onClick={() => setShowTelefones(true)}
							outline
						>
							<span>
								<i class="fas fa-phone"></i>
							</span>
							<Badge color="primary" className="badge-md badge-circle badge-floating border-white">
								{telefones.length}
							</Badge>
						</Button>
					</InputGroupAddon>
				)}
			</>
		)
	}

	const RestaurandoProgress = ({}) => (
		<>
			<Spinner color="secondary" />
			<br />
			<label style={{ color: "#fff" }}>Restaurando oportunidade...</label>
		</>
	)

	async function changeEstagio(estagio) {
		if (estagio && oportunidade.id) await loadOportunidade(oportunidade.id)
	}

	async function handlePoliticaAssinada() {
		if (oportunidade.id) await loadOportunidade(oportunidade.id)
	}

	const customStyles = {
		control: (base) => ({
			...base,
			height: "calc(2.75rem + 2px)",
		}),
	}

	function handleShowEmailAvulso(e) {
		setEmailAvulso(e)
		setShowEmailAvulso(true)
	}

	function handleHiddeEmailAvulso() {
		setShowEmailAvulso(false)
	}
	function canDisable() {
		return !campanha

		// if (isNew() || campanha) {
		//     return true
		// }else if (!campanha) {
		//     return false
		// }else{
		//     if (oportunidade.cliente_id == oportunidade.user_id) {
		//         return true
		//     }else{
		//         return false
		//     }
		// }
		// !isNew() || !campanha && oportunidade.cliente_id == oportunidade.user_id
	}

	async function loadEmailsAndSMS(id) {
		try {
			const { data } = await api.get(`/common/empresas/${empresaSelecionada?.id}/clientes/${id}/malas-diretas`)
			setEmailsAndSMS(data)
		} catch (error) {
			console.log(error)
			notify("danger", "Não foi possível carregar os emails e/ou SMS.")
		}
	}

	let sms = []
	let emails = []

	if (emailsAndSMS) {
		emailsAndSMS.forEach((element) => {
			if (element.enviar_sms) sms.push(element)
			if (element.enviar_email) emails.push(element)
		})
	}

	const convertDescToObject = () => {
		convertDescToObjectIf: if (oportunidade.descricao) {
			const table = {}
			const pairs = (oportunidade.descricao && oportunidade.descricao.split(",")) || []

			if (pairs.length === 0) break convertDescToObjectIf

			if (pairs.length >= 1) {
				if (pairs[0].split(":").length != 2) {
					break convertDescToObjectIf
				}
			}

			pairs.forEach((pair) => {
				const [key, ...value] = pair.split(":").map((str) => str.trim())
				if (value) {
					table[key.toLocaleLowerCase()] = value.join(" | ")
				}
			})

			console.log({ observacao: oportunidade.descricao, table })

			setObservationTable(table)
		}
	}

	useEffect(() => {
		convertDescToObject()
	}, [oportunidade])

	const [isModalVisible, setIsModalVisible] = useState(false)

	return (
		<>
			<Modal size="lg" isOpen={isModalVisible} toggle={() => setIsModalVisible(!isModalVisible)}>
				<ModalHeader>Existem oportunidades registradas para este cliente</ModalHeader>
				<ModalBody>
					<RSTable responsive className="text-center">
						<thead>
							<tr>
								<th>#</th>
								<th>Nome do Cliente</th>
								<th>Data de Criação</th>
								<th>Data de Agendamento</th>
							</tr>
						</thead>
						<tbody>
							{oportunidadesExistentes.map((op, index) => (
								<tr
									onClick={() => {
										setIsModalVisible(false)
										history.push(`/admin/oportunidades/${op.id}/edit`)
									}}
								>
									<th scope="row">{index + 1}</th>
									<td>{op?.cliente.nome}</td>
									<td>{new Date(op?.created_at).toLocaleDateString("pt-BR")}</td>
									<td>{new Date(op?.data_agendamento).toLocaleDateString("pt-BR")}</td>
								</tr>
							))}
						</tbody>
					</RSTable>
					<Button color="primary" onClick={() => setIsModalVisible(false)}>
						Ok
					</Button>
				</ModalBody>
			</Modal>
			<div className="rna-wrapper">
				<NotificationAlert ref={notificationAlert} />
			</div>
			{alert}
			{oportunidade.id && (
				<Telefones show={showTelefones} onHidde={() => setShowTelefones(false)} telefones={telefones} />
			)}

			{oportunidade.id && (
				<Preview
					isOpen={showPreview}
					showHistorico={true}
					readOnly={true}
					close={() => {
						setShowPreview(!showPreview)
						setPublicacaoPreviewId(null)
					}}
					publicacaoId={publicacaoPreviewId}

					// onChangePublicacao={search}
				/>
			)}

			<Container className={readOnly || "mt--6"} fluid>
				<Card>
					{!readOnly && (
						<CardHeader
							style={{
								position: "sticky",
								top: 0,
								zIndex: 200,
							}}
						>
							{/* Aqui está o cabeçalho, contendo "programa de indicação" */}
							<Header
								onColaboradorChanged={onColaboradorChanged}
								oportunidade={oportunidade ? oportunidade : undefined}
								anuncio={anuncio ? (anuncio.id ? anuncio.id : undefined) : undefined}
								handleChangeEstagio={changeEstagio}
								handlePoliticaAssinada={handlePoliticaAssinada}
								onChangeMotivoEncerramento={(motivoEncerramento) =>
									setOportunidade({
										...oportunidade,
										motivo_encerramento_id: motivoEncerramento.id,
										motivoEncerramento,
										data_encerramento: new Date(),
									})
								}
								notify={notify}
								history={history}
								currentdataAgendamento={currentdataAgendamento}
							/>
						</CardHeader>
					)}
					<CardBody outline body color={"danger"}>
						<>
							{/* Modal do cliente */}
							<Modal
								size="lg"
								className="modal-dialog-centered"
								isOpen={showClienteModal}
								toggle={() => setShowClienteModal(!showClienteModal)}
							>
								<FormCliente
									{...props}
									onTelefonesChange={loadTelefonesCliente}
									showHeader={false}
									clienteId={cliente}
									oportunidadeId={oportunidadeId}
									onSuccess={(cliente) => {
										setShowClienteModal(false)
										if (cliente) fillCliente(cliente)
									}}
									goBack={() => setShowClienteModal(!showClienteModal)}
								/>
							</Modal>

							<EnviarEmailModal
								show={showEmailAvulso}
								hidde={handleHiddeEmailAvulso}
								cliente_id={cliente}
								cliente_nome={oportunidade?.cliente?.nome}
								cliente_email={emailAvulso?.destinatario}
								oportunidade_id={oportunidadeId}
								notify={notify}
								emailAvulso={emailAvulso}
							/>
						</>

						<Row>
							<Col sm="12" md="12" lg="7">
								<Row>
									<Col sm="12" md="12" lg="12">
										<FormGroup>
											<legend class="w-auto" style={{ margin: 0 }}>
												<label className="form-control-label">Cliente*</label>
											</legend>
											<InputGroup>
												<ClienteInputContainer className="op-new--input">
													<Select2
														id="select-cliente"
														ref={clieneSelect}
														className="form-control"
														onSelect={(e) => setCliente(e.target.value)}
														options={clienteOptions()}
														value={cliente}
														forceUpdateValue={true}
													/>
												</ClienteInputContainer>
												<ClienteInputGoup />
											</InputGroup>
											<small class="text-danger">
												{erros.cliente_id ? erros.cliente_id : ""}
											</small>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col sm="12" md="6" lg="6">
										<FormGroup>
											<legend class="w-auto" style={{ margin: 0 }}>
												<label className="form-control-label">Data de agendamento*</label>
											</legend>
											<InputGroup className="input-group">
												<ReactDatetime
													closeOnSelect={true}
													isValidDate={(current) => {
														return current.isAfter(
															new Date(new Date().setDate(new Date().getDate() - 1))
														)
													}}
													locale={"pt-br"}
													value={dataAgendamento ? moment(dataAgendamento) : null}
													inputProps={{
														placeholder: "Data de agendamento...",
														name: "data_agendamento",
														id: "data_agendamento",
														autocomplete: "off",
														disabled: readOnly,
														required: true,
													}}
													dateFormat="DD/MM/YYYY"
													timeFormat="HH:mm"
													onChange={(e) => {
														setDataAgendamento(e)
														if (typeof e == `string` && moment(e).isValid()) {
															setCurrentdataAgendamento(moment(e))
														}
													}}
												/>
												{!readOnly && (
													<InputGroupAddon addonType="append">
														<InputGroupText
															style={{
																padding: "10px",
															}}
														>
															<i class="far fa-calendar-alt" />
														</InputGroupText>
													</InputGroupAddon>
												)}
											</InputGroup>
											<small class="text-danger">{erros.data_agendamento || ""}</small>
										</FormGroup>
									</Col>
									<Col sm="12" md="6" lg="6">
										<FormGroup>
											<legend class="w-auto" style={{ margin: 0 }}>
												<label className="form-control-label">Expectativa*</label>
											</legend>
											<Select2
												disabled={readOnly}
												className="form-control"
												onSelect={(e) => setExpectativa(e.target.value)}
												options={{
													placeholder: "Selecione expectativa...",
												}}
												value={expectativa}
												data={expectativas.map((item) => ({
													id: item.id,
													text: item.nome,
												}))}
											/>
											<small class="text-danger">{erros.expectativa_id || ""}</small>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col sm="12" md="6" lg="6">
										<FormGroup>
											<legend class="w-auto" style={{ margin: 0 }}>
												<label className="form-control-label">Campanha*</label>
											</legend>
											<Select
												className="basic-single"
												classNamePrefix="select"
												// isClearable={isNew()}
												// isSearchable={isNew()}
												isDisabled={!isNew() && user.id != oportunidade.criador_id}
												styles={customStyles}
												placeholder={"Selecione uma Campanha"}
												options={campanhas.map((item) => ({
													value: item.id,
													label: item.nome,
													id: item.id,
												}))}
												value={campanha}
												onChange={(e) => {
													setCampanha(e)
												}}
											/>
											<small class="text-danger">
												{erros.campanha_id ? erros.campanha_id : ""}
											</small>
										</FormGroup>
									</Col>
									<Col sm="12" md="6" lg="6">
										<FormGroup>
											<legend class="w-auto" style={{ margin: 0 }}>
												<label className="form-control-label">Anúncio*</label>
											</legend>
											{oportunidade &&
											oportunidade.publicacao_id &&
											oportunidade.publicacao_id > 0 ? (
												<InputGroup className="input-group">
													<AnuncioInputContainer>
														<Select
															className="basic-single"
															classNamePrefix="select"
															isClearable={isNew()}
															isSearchable={isNew()}
															placeholder={"Selecione um Anúncio"}
															title={anuncio?.nome}
															styles={customStyles}
															isDisabled={
																campanha
																	? oportunidade.criador_id
																		? user.id != oportunidade.criador_id
																		: false
																	: true
															}
															options={anuncios.map((item) => ({
																value: item.id,
																label: item.nome,
																id: item.id,
															}))}
															value={anuncio}
															onChange={(e) => {
																setAnuncio(e)
															}}
														/>
													</AnuncioInputContainer>
													<InputGroupAddon addonType="append">
														<Button
															title="Publicação do Anúncio"
															color="primary"
															onClick={() => {
																setPublicacaoPreviewId(oportunidade.publicacao_id)
																setShowPreview(!showPreview)
															}}
															outline
														>
															<span className="btn-inner--icon">
																<i className="far fa-eye"></i>
															</span>
														</Button>
													</InputGroupAddon>
												</InputGroup>
											) : (
												<Select
													className="basic-single"
													classNamePrefix="select"
													isClearable={isNew()}
													isSearchable={isNew()}
													// isDisabled={!isNew() || !campanha}
													isDisabled={
														campanha
															? oportunidade.criador_id
																? user.id != oportunidade.criador_id
																: false
															: true
													}
													// isDisabled={!campanha}
													placeholder={"Selecione um Anúncio"}
													styles={customStyles}
													options={anuncios.map((item) => ({
														value: item.id,
														label: item.nome,
														id: item.id,
													}))}
													value={anuncio}
													onChange={(e) => {
														setAnuncio(e)
													}}
												/>
											)}

											<small class="text-danger">
												{erros.anuncio_id ? erros.anuncio_id : ""}
											</small>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col>
										{!id && hasPermission("ver-todas-oportunidades") && (
											<FuncionarioSelect
												funcionarioId={userId}
												onFuncionarioChenged={setUserId}
											/>
										)}
									</Col>
								</Row>
								<Row>
									<Col sm="12" md="12" lg="12">
										<FormGroup>
											<legend class="w-auto" style={{ margin: 0 }}>
												<label className="form-control-label">Tags</label>
											</legend>
											<div
												style={{
													minHeight: "calc(2.75rem + 2px)",
													border: "1px solid #DEE2E6",
													padding: 4,
													borderRadius: 4,
												}}
											>
												<TagsInput
													disabled={readOnly}
													className="bootstrap-tagsinput"
													onChange={handleTags}
													tagProps={{
														className: "tag badge badge-primary mr-1",
													}}
													value={tags}
													inputProps={{
														className: "",
														placeholder: "Adicionar tags...",
													}}
												/>
											</div>
											<small class="text-danger">{erros.tags ? erros.tags : ""}</small>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									{oportunidade && oportunidade.descricao && (
										<Col>
											<FormGroup>
												<legend class="w-auto" style={{ margin: 0 }}>
													<label className="form-control-label">Observações</label>
												</legend>
												{obeservationTable ? (
													<Table hover responsive size="sm" striped>
														<tbody>
															{Object.keys(obeservationTable).map((key) => {
																return (
																	<tr key={key}>
																		<th scope="row">{`${key
																			.charAt(0)
																			.toUpperCase()}${key.slice(1)}`}</th>
																		<td>{obeservationTable[key]}</td>
																	</tr>
																)
															})}
														</tbody>
													</Table>
												) : (
													<ReactQuill
														value={oportunidade.descricao}
														theme="snow"
														readOnly={true}
														modules={{
															toolbar: false,
														}}
													/>
												)}
											</FormGroup>
										</Col>
									)}
								</Row>
								<Row>
									{oportunidade && oportunidade.indicador && (
										<Col>
											<FormGroup>
												<legend class="w-auto" style={{ margin: 0 }}>
													<label className="form-control-label">Indicado por</label>
												</legend>
												<Input
													readOnly
													value={
														oportunidade?.indicador?.nome +
														" | (" +
														oportunidade?.indicador.telefones[0]?.ddd +
														") " +
														oportunidade?.indicador.telefones[0]?.numero
													}
												/>
											</FormGroup>
										</Col>
									)}
								</Row>
							</Col>
							<Col sm="12" md="12" lg="5">
								<Row>
									<Col lg="12">
										<div
											style={{
												display: "flex",
												justifyContent: "center",
											}}
										>
											<h3>Produtos</h3>
										</div>
										<Produtos
											readOnly={readOnly}
											notify={notify}
											oportunidade={oportunidade}
											oportunidadeId={oportunidadeId}
											oportunidadeIdToCrossSelling={oportunidadeIdToCrossSelling}
											onProtudosChange={setProdutos}
											extraProps={{
												nomeCliente: oportunidade?.cliente?.nome,
												dataAgendamento: oportunidade?.data_agendamento,
											}}
										/>
										<small class="text-danger">{erros.produtos || ""}</small>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col
								lg="12"
								style={{
									paddingTop: 20,
								}}
							>
								<Nav tabs>
									<NavItem>
										<NavLink
											className={`${
												currentActiveTab === "1" ? "active" : null
											} form-control-label`}
											onClick={() => toggle("1")}
											style={{ cursor: "pointer" }}
										>
											Comentários
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={`${
												currentActiveTab === "2" ? "active" : null
											} form-control-label`}
											onClick={() => toggle("2")}
											style={{ cursor: "pointer" }}
											disabled={isNew()}
										>
											Emails
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink
											className={`${
												currentActiveTab === "3" ? "active" : null
											} form-control-label`}
											onClick={() => toggle("3")}
											style={{ cursor: "pointer" }}
											disabled={isNew()}
										>
											SMS
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink
											className={`${
												currentActiveTab === "4" ? "active" : null
											} form-control-label`}
											style={{ cursor: "pointer" }}
											onClick={() => toggle("4")}
										>
											<span>Conversas</span>
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent activeTab={currentActiveTab}>
									<TabPane tabId="1">
										<Row>
											<Col sm="12">
												<Comentarios
													readOnly={readOnly}
													notify={notify}
													oportunidadeId={oportunidadeId}
													oportunidadeIdToCrossSelling={oportunidadeIdToCrossSelling}
													tipoCrossSelling={tipoCrossSelling}
													onComentariosChanged={setComentarios}
													handleShowEmailAvulso={(e) => handleShowEmailAvulso(e)}
												/>
												<small class="text-danger">{erros.comentarios || ""}</small>
											</Col>
										</Row>
									</TabPane>
									<TabPane tabId="2">
										<EnviarEmailModal
											show={newEmailAvulso}
											hidde={handleCloseModal}
											cliente_id={cliente}
											cliente_nome={oportunidade?.cliente?.nome}
											cliente_email={oportunidade?.cliente?.email}
											oportunidade_id={oportunidadeId}
											notify={notify}
										/>
										<Row>
											<Col className="ml-4 mt-4">
												<Button
													color="primary"
													type="button"
													className="btn-icon btn-3"
													size="sm"
													onClick={() => {
														setNewEmailAvulso(true)
													}}
												>
													<span className="btn-inner--icon">
														<i className="ni ni-fat-add"></i>
													</span>
													<span className="btn-inner--text">Novo</span>
												</Button>
											</Col>
										</Row>
										<EmailsOrSMSList data={emails} type="email" />
									</TabPane>
									<TabPane tabId="3">
										<EmailsOrSMSList data={sms} type="sms" />
									</TabPane>
									<TabPane tabId="4">
										<Row style={{ maxHeight: "45vh", overflowY: "scroll" }}>
											<Col sm="12">
												{mensagens.length === 0 && (
													<p className="font-weight-normal pt-4 text-center">
														Este cliente não recebeu/enviou mensagens.
													</p>
												)}
												{mensagens.length > 0 && (
													<>
														<FABConversas
															title="Ir para o Playnee Conversas"
															onClick={selecionarContatoPlayneeConversas}
														>
															Ir para a conversa <i className="fas fa-comments"></i>
														</FABConversas>
														<ChatWindow messages={mensagens} />
													</>
												)}
											</Col>
										</Row>
									</TabPane>
								</TabContent>
							</Col>
						</Row>
					</CardBody>
					{!readOnly && (
						<CardFooter
							style={{
								position: "sticky",
								bottom: 0,
								border: "2 solid",
								boxShadow: "0px 0px 2px 2px #f5f5f5",
								zIndex: "9",
							}}
							className={oportunidade.data_encerramento ? "bg-gradient-danger" : ""}
						>
							{oportunidade.data_encerramento && (
								<>
									<div className="text-center">
										{saving ? (
											<RestaurandoProgress />
										) : (
											<>
												<h4
													style={{
														color: "white",
													}}
												>
													{`Oportunidade encerrada em ${new Date(
														oportunidade.data_encerramento
													).toLocaleDateString("pt-br", {
														year: "numeric",
														month: "long",
														day: "numeric",
													})}`}
													<br />
													{oportunidade.motivoEncerramento.nome}
													<br />
													<Button
														className="btn-black mt-2"
														style={{
															color: "#fff",
															textDecoration: "underline",
														}}
														title="Restaurar oportunidade"
														color="link"
														type="button"
														onClick={handleRestaurarOportunidade}
													>
														Restaurar
													</Button>

													<Button
														className="btn-black mt-2"
														style={{
															color: "#fff",
															textDecoration: "underline",
														}}
														title="Nova Oportunidade Cross-Selling"
														color="link"
														type="button"
														onClick={handleCrossSellingOportunidade}
													>
														Cross-Selling
													</Button>

													<Button
														className="btn-white mt-2"
														color="default"
														type="button"
														onClick={() => history.goBack()}
													>
														Voltar
													</Button>
												</h4>
											</>
										)}
									</div>
								</>
							)}
							<Row>
								<Col>
									<div className="float-left ">
										{!isNew() && !oportunidade.data_encerramento && (
											<Encerrar
												oportunidade={oportunidade}
												notify={notify}
												onEncerrado={() => loadOportunidade(oportunidadeId)}
											/>
										)}
									</div>
									<div className="float-right ">
										{!oportunidade.data_encerramento && (
											<Button
												className="ml-auto"
												color="link"
												data-dismiss="modal"
												disabled={saving}
												type="button"
												onClick={(e) => {
													e.preventDefault()
													history.goBack()
												}}
											>
												Voltar
											</Button>
										)}

										{!oportunidade.data_encerramento && (
											<Button
												color="primary"
												type="submit"
												disabled={saving}
												onClick={() => handleSalvar(true)}
											>
												<Spinner hidden={!saving} className="mr-2" color="light" size="sm" />
												Salvar
											</Button>
										)}

										{!oportunidade.data_encerramento && (
											<Button
												color="primary"
												type="submit"
												disabled={saving}
												onClick={() => handleSalvar(false)}
											>
												<Spinner hidden={!saving} className="mr-2" color="light" size="sm" />
												Salvar e Fechar
											</Button>
										)}
									</div>
								</Col>
							</Row>
						</CardFooter>
					)}
				</Card>
			</Container>
		</>
	)
}
