import React, { useState, useEffect, useContext, useRef } from "react";
import EmpresaContext from "../../../../../../contexts/Empresa";
import api from "../../../../../../services/api";
import Avatar from "../../../../../../components/Avatar";

// svg
import goldPosition from "../../../../../../assets/img/PlayneeRanking/Ouro.png";
import silverPosition from "../../../../../../assets/img/PlayneeRanking/Prata.png";
import bronzePosition from "../../../../../../assets/img/PlayneeRanking/Bronze.png";
//

import "./style.css";

interface Props {
  colaboradores: Array<any>;
  unidade: any;
  pageProperties: any;
  onTableChange(
    type: string,
    sortProperties: {
      page: number;
      sizePerPage: number;
      sortField: string;
      sortOrder: string;
    }
  ): Promise<void>;
  notify(type: string, msg: string): void;
  loading: boolean;
}

export const Ranking: React.FC<Props> = ({
  colaboradores,
  unidade,
  pageProperties,
  onTableChange,
  notify,
  loading,
  ...props
}) => {
  function convertToMoney(value: number) {
    if (unidade && unidade == "M") {
      return Number(value).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    } else {
      return parseInt(String(value));
    }
  }

  const Colaborador: React.FC<{ data: any[] }> = ({ data }) => {
    // console.log(data);
    if (data.length === 0) {
      return (
        <div className="rankingLateralNoItem">
          <p>Nenhum item encontrado</p>
        </div>
      );
    } else {
      return (
        <>
          {data.map((item, index) => {
            switch (index) {
              case 0:
                return (
                  <div className="rankingLateral">
                    <div className="boxAvatar">
                      <Avatar
                        title={item.user.name}
                        user={item.user}
                        className="avatar"
                        style={{
                          cursor: "default",
                          border: "3px #0638CB solid",
                        }}
                      />
                    </div>
                    <div className="boxInfoRanking">
                      <div className="boxUserName">
                        <p>{item.user.name}</p>
                      </div>
                      <div className="boxMeta">
                        <div className="boxMeta-venda">
                          <p className="venda">
                            {convertToMoney(item.vendas_colaborador)}
                          </p>
                          <p>Vendas</p>
                        </div>
                        <div className="boxMeta-meta">
                          <p className="meta">
                            {convertToMoney(item.meta_geral)}
                          </p>
                          <p>Meta</p>
                        </div>
                        <div className="boxMeta-faltam">
                          <p className="faltam">
                            {item.vendas_colaborador < item.meta_geral
                              ? convertToMoney(
                                  item.meta_geral - item.vendas_colaborador
                                )
                              : convertToMoney(0)}
                          </p>
                          <p>Faltam</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              case 1:
                return (
                  <div className="rankingLateral">
                    <div className="boxAvatar">
                      <Avatar
                        title={item.user.name}
                        user={item.user}
                        className="avatar"
                        style={{
                          cursor: "default",
                          border: "3px #8682B9 solid",
                        }}
                      />
                    </div>
                    <div className="boxInfoRanking">
                      <div className="boxUserName">
                        <p>{item.user.name}</p>
                      </div>
                      <div className="boxMeta">
                        <div className="boxMeta-venda">
                          <p className="venda">
                            {convertToMoney(item.vendas_colaborador)}
                          </p>
                          <p>Vendas</p>
                        </div>
                        <div className="boxMeta-meta">
                          <p className="meta">
                            {convertToMoney(item.meta_geral)}
                          </p>
                          <p>Meta</p>
                        </div>
                        <div className="boxMeta-faltam">
                          <p className="faltam">
                            {item.vendas_colaborador < item.meta_geral
                              ? convertToMoney(
                                  item.meta_geral - item.vendas_colaborador
                                )
                              : convertToMoney(0)}
                          </p>
                          <p>Faltam</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              case 2:
                return (
                  <div className="rankingLateral">
                    <div className="boxAvatar">
                      <Avatar
                        title={item.user.name}
                        user={item.user}
                        className="avatar"
                        style={{
                          cursor: "default",
                          border: "3px #E0BC6B solid",
                        }}
                      />
                    </div>
                    <div className="boxInfoRanking">
                      <div className="boxUserName">
                        <p>{item.user.name}</p>
                      </div>
                      <div className="boxMeta">
                        <div className="boxMeta-venda">
                          <p className="venda">
                            {convertToMoney(item.vendas_colaborador)}
                          </p>
                          <p>Vendas</p>
                        </div>
                        <div className="boxMeta-meta">
                          <p className="meta">
                            {convertToMoney(item.meta_geral)}
                          </p>
                          <p>Meta</p>
                        </div>
                        <div className="boxMeta-faltam">
                          <p className="faltam">
                            {item.vendas_colaborador < item.meta_geral
                              ? convertToMoney(
                                  item.meta_geral - item.vendas_colaborador
                                )
                              : convertToMoney(0)}
                          </p>
                          <p>Faltam</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              default:
                return (
                  <div className="rankingLateral">
                    <div className="boxAvatar">
                      <Avatar
                        title={item.user.name}
                        user={item.user}
                        className="avatar"
                        style={{
                          cursor: "default",
                          border: "3px #A5826D solid",
                        }}
                      />
                    </div>
                    <div className="boxInfoRanking">
                      <div className="boxUserName">
                        <p>{item.user.name}</p>
                      </div>
                      <div className="boxMeta">
                        <div className="boxMeta-venda">
                          <p className="venda">
                            {convertToMoney(item.vendas_colaborador)}
                          </p>
                          <p>Vendas</p>
                        </div>
                        <div className="boxMeta-meta">
                          <p className="meta">
                            {convertToMoney(item.meta_geral)}
                          </p>
                          <p>Meta</p>
                        </div>
                        <div className="boxMeta-faltam">
                          <p className="faltam">
                            {item.vendas_colaborador < item.meta_geral
                              ? convertToMoney(
                                  item.meta_geral - item.vendas_colaborador
                                )
                              : convertToMoney(0)}
                          </p>
                          <p>Faltam</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
            }
          })}
        </>
      );
    }
  };

  // tentativa de retornar jsx com o card gold completo e posicionado

  interface user {
    avatar: string;
    avatar_url: string;
    cpf_cnpj: any;
    created_at: Date;
    email: string;
    id: number;
    name: string;
    recovery_password_token: any;
    situacao: string;
    status: boolean;
    updated_at: Date;
  }
  interface colaborador {
    impacto: number;
    meta_colaborador: number;
    meta_geral: number;
    name: string;
    user: user;
    user_id: number;
    vendas_colaborador: number;
    vendas_geral: number;
  }

  const Gold: React.FC<{ data: colaborador | null }> = ({ data }) => {
    console.log(data);
    if (data !== null) {
      return (
        <>
          {/* titulo do desafio */}
          {/* <h1 className='tituloRanking'>MAISLASER</h1> */}
          <img className="goldPosition" src={goldPosition} />
          <div className="goldAvatar">
            <Avatar
              title={data.user.name}
              user={data.user}
              className="avatar"
              style={{
                cursor: "default",
              }}
            />
            <p>{data.name}</p>
            <div style={{ borderBottom: "1px #8B6C34 solid", width: "80%" }} />
          </div>
          {/* premio */}
          {/* <div className='premio'>
            <div className='premioGold' />
            <p>JBL</p>
          </div> */}
        </>
      );
    } else {
      return (
        <>
          <img className="goldPosition" src={goldPosition} />
          <div className="goldElipse">
            <div className="elipse" />
          </div>
        </>
      );
    }
  };

  const Silver: React.FC<{ data: colaborador | null }> = ({ data }) => {
    if (data !== null) {
      return (
        <>
          <img className="silverPosition" src={silverPosition} />
          <div className="silverAvatar">
            <Avatar
              title={data.user.name}
              user={data.user}
              className="avatar"
              style={{
                cursor: "default",
              }}
            />
            <p>{data.name}</p>
            <div style={{ borderBottom: "1px #6F6F6F solid", width: "100%" }} />
          </div>
          {/* premio */}
          {/* <div className='premio'>
            <div className='premioSilver' />
            <p>Ingressos</p>
          </div> */}
        </>
      );
    } else {
      return (
        <>
          <img className="silverPosition" src={silverPosition} />
          <div className="silverElipse">
            <div className="elipse" />
          </div>
        </>
      );
    }
  };

  const Bronze: React.FC<{ data: colaborador | null }> = ({ data }) => {
    if (data !== null) {
      return (
        <>
          <img className="bronzePosition" src={bronzePosition} />
          <div className="bronzeAvatar">
            <Avatar
              title={data.user.name}
              user={data.user}
              className="avatar"
              style={{
                cursor: "default",
              }}
            />
            <p>{data.name}</p>
            <div style={{ borderBottom: "1px #E2C6B3 solid", width: "95%" }} />
          </div>
          {/* premio */}
          {/* <div className='premio'>
            <div className='premioBronze' />
            <p>Chocolate</p>
          </div> */}
        </>
      );
    } else {
      return (
        <>
          <img className="bronzePosition" src={bronzePosition} />
          <div className="bronzeElipse">
            <div className="elipse" />
          </div>
        </>
      );
    }
  };

  return (
    <div className="ranking">
      <div className="podioColaboradores">
        <div className="silverColaborador">
          <Silver
            data={
              colaboradores
                ? colaboradores[1]
                  ? colaboradores[1]
                  : null
                : null
            }
          />
        </div>
        <div className="goldColaborador">
          <Gold
            data={
              colaboradores
                ? colaboradores[0]
                  ? colaboradores[0]
                  : null
                : null
            }
          />
        </div>
        <div className="bronzeColaborador">
          <Bronze
            data={
              colaboradores
                ? colaboradores[2]
                  ? colaboradores[2]
                  : null
                : null
            }
          />
        </div>
      </div>
      <div className="rankingTen">
        <Colaborador data={colaboradores} />
      </div>
    </div>
  );
};
