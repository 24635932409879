import React, { useContext, useEffect, useState } from "react"
import { ButtonContainer } from "../../styles"
import { Button, ListGroup, ListGroupItem, Spinner, UncontrolledTooltip, Badge } from "reactstrap"
import { PortaisModal } from "./Modal"
import api from "../../../../../../../services/api"
import EmpresaContext from "../../../../../../../contexts/Empresa"
import ReactBSAlert from "react-bootstrap-sweetalert"

interface Integration {
	id: number
	empresa_id?: number
	user_id?: number
	sistema_id: number
	valor: string
	created_at: Date
	updated_at: Date
	is_revoked: boolean
	nome_sistema: string
	hash_sistema: string
	tipo_sistema: string
}

export const Portais: React.FC = () => {
	const [showModal, setShowModal] = useState<boolean>(false)
	const [integrations, setIntegrations] = useState<Integration[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [alert, setAlert] = useState<any>(null)
	const [emails, setEmails] = useState<Integration[]>()

	const { empresaSelecionada } = useContext(EmpresaContext)

	const getIntegrations = async () => {
		const response = await api.get(
			`esb/integracoes/ativas?&types[]=text&types[]=oauth&types[]=portal&empresaId=${empresaSelecionada?.id}`
		)

		setIntegrations(response.data)
	}

	const getEmails = async () => {
		const response = await api.get(`esb/integracoes/ativas?types[]=service&empresaId=${empresaSelecionada?.id}`)

		setEmails(response.data)
	}

	const handleModalChange = () => {
		getIntegrations()
		setShowModal((prev) => !prev)
	}

	const deleteIntegration = async (id: number) => {
		try {
			setIsLoading(true)

			await api.delete(`esb/integracoes/${id}`)

			getIntegrations()
			setIsLoading(false)

			setAlert(
				<ReactBSAlert
					success
					style={{ display: "block" }}
					title="Sucesso!"
					onConfirm={() => setAlert(null)}
					onCancel={() => setAlert(null)}
					confirmBtnBsStyle="primary"
					confirmBtnText="Ok"
					btnSize=""
				>
					Integração removida com sucesso!
				</ReactBSAlert>
			)
		} catch (err) {
			setIsLoading(false)

			setAlert(
				<ReactBSAlert
					error
					style={{ display: "block" }}
					title="Falha!"
					onConfirm={() => setAlert(null)}
					onCancel={() => setAlert(null)}
					confirmBtnBsStyle="primary"
					confirmBtnText="Ok"
					btnSize=""
				>
					Não foi possível remover integração!
				</ReactBSAlert>
			)

			console.log(err)
		}
	}

	function handleDeleteIntegration(id: number) {
		setAlert(
			<ReactBSAlert
				warning
				style={{ display: "block" }}
				title="Tem certeza que deseja remover a integração?"
				onConfirm={() => deleteIntegration(id)}
				onCancel={() => setAlert(null)}
				confirmBtnBsStyle="danger"
				confirmBtnText="Sim"
				btnSize=""
				showCancel
				focusCancelBtn
			/>
		)
	}

	useEffect(() => {
		getIntegrations()
		getEmails()
	}, [])

	return (
		<>
			{alert}
			<div>
				<ButtonContainer className="mb-4">
					<Button caret color="primary" onClick={handleModalChange}>
						<span className="btn-inner--text">Integrar Portal</span>
					</Button>
					<PortaisModal show={showModal} toggle={handleModalChange}></PortaisModal>
				</ButtonContainer>
				<ListGroup className="list " flush>
					{integrations.length <= 0 && (
						<ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
							<h3 className="text-center">Ainda não há nenhuma integração.</h3>
						</ListGroupItem>
					)}
					{integrations.map((item: Integration) => {
						return (
							<ListGroupItem
								key={item.id}
								className="checklist-entry flex-column align-items-start py-4 px-4"
							>
								<div className="d-flex justify-content-between">
									<div
										style={{ alignItems: "center", gap: ".5rem" }}
										className="d-flex checklist-primary"
									>
										<h5 className="checklist-title mb-0">{item.nome_sistema} </h5>
										<Badge color="primary" className="mb-0" style={{ color: "white" }}>
											{emails?.find((email) => email.id === +item.valor)?.valor}
										</Badge>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
										}}
									>
										<div className="custom-control custom-checkbox custom-checkbox-primary">
											<div className="btn-group" role="group">
												<Button
													id={"Tooltip-2" + item.id}
													className="btn-sm"
													color="danger"
													onClick={(e) => {
														handleDeleteIntegration(item.id)
													}}
												>
													<i className="fas fa-trash pr-2"></i>
													Remover
													<Spinner
														style={{ marginLeft: "4px" }}
														hidden={!isLoading}
														className="mr-2"
														color="light"
														size="sm"
													/>
												</Button>

												<UncontrolledTooltip
													placement="left-end"
													target={"Tooltip-2" + item.id}
												>
													Clique para remover o integração: {item.valor}
												</UncontrolledTooltip>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
						)
					})}
				</ListGroup>
			</div>
		</>
	)
}
